import axios from "axios";
import store from "../redux/store";

class Api {
  constructor() {
    this.instance = axios.create({});
    this.instance.interceptors.request.use((req) => {
      // Include token validation here.

      const user = store.getState()?.["auth"]?.["user"];
      const _token = user?.["accessToken"];
      if (_token) {
        req.headers["X_AUTH_TOKEN"] = `${_token}`;
      }

      if (process.env.HTTP_ORIGIN) {
        req.headers["HTTP_ORIGIN"] = `${process.env.HTTP_ORIGIN}`;
      }

      return req;
    });
  }

  get(endpoint, params = {}, config = {}) {
    return this.instance
      .get(endpoint, {
        ...config,
        params,
      })
      .catch((error) => {
        console.error(error, config);
        return error.response;
      });
  }

  delete(endpoint, data = {}, config) {
    return this.instance
      .delete(endpoint, { ...config, data })
      .catch((error) => {
        console.error(error, config);
        return error.response;
      });
  }

  post(endpoint, data = {}, config) {
    return this.instance.post(endpoint, data, config).catch((error) => {
      console.error(error, config);
      return error.response;
    });
  }

  put(endpoint, data = {}, config) {
    return this.instance.put(endpoint, data, config).catch((error) => {
      console.error(error, config);
      return error.response;
    });
  }
}

export default new Api();
