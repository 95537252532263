import Cookies from "universal-cookie";

const cookies = new Cookies();
const compare_cookie = "compare_list";

export default function checkProductsAvailability(availability) {
  switch (availability) {
    case "available":
      return false;
    case "disabled":
      return true;
    default:
      return false;
  }
}

export const setCookie = (product_ids) => {
  cookies.set(compare_cookie, product_ids, { path: "/" });
};

export const setCompareCookie = (product_id, toastMessage) => {
  let res = cookies.get(compare_cookie);

  // When Cookie already exist and it has some value ...
  if (res !== "" && res !== " " && res !== undefined) {
    let productIds = res.split(" ");

    // When user click on same product that already in compare list ...
    if (productIds.includes(product_id.toString())) {
      // Remove id from the cookies ...
      productIds = productIds.filter((item) => item !== product_id.toString());
      toastMessage("warning", "item removed from the compare");
    } else {
      // Apend id from the cookies ...
      productIds.push(`${product_id}`);
      toastMessage("success", "item added for the compare");
    }

    setCookie(productIds.join(" "));
  }

  // Create new Cookie when it does not exist ...
  else {
    setCookie(product_id);
    toastMessage("success", "item added for the compare");
  }
};

export function getCompareProducts() {
  const res = cookies.get(compare_cookie);
  return res ? res.split(" ") : [];
}
