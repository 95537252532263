import React from "react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./src/redux/store";

const swrConfig = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  revalidateOnReconnect: true,
  fetcher: (url) => fetch(url).then((r) => r.json()),
};

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <SWRConfig value={swrConfig}>
        {element}
        <ToastContainer
          position="top-right"
          className="toastify_container"
          toastClassName="toastify_root"
          bodyClassName="toastify_body"
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </SWRConfig>
    </Provider>
  );
};
