exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account-settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-account-success-js": () => import("./../../../src/pages/account-success.js" /* webpackChunkName: "component---src-pages-account-success-js" */),
  "component---src-pages-addresses-js": () => import("./../../../src/pages/addresses.js" /* webpackChunkName: "component---src-pages-addresses-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-create-update-address-js": () => import("./../../../src/pages/create-update-address.js" /* webpackChunkName: "component---src-pages-create-update-address-js" */),
  "component---src-pages-gift-certificate-js": () => import("./../../../src/pages/gift-certificate.js" /* webpackChunkName: "component---src-pages-gift-certificate-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-list-js": () => import("./../../../src/pages/order-list.js" /* webpackChunkName: "component---src-pages-order-list-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-success-subscribe-js": () => import("./../../../src/pages/success_subscribe.js" /* webpackChunkName: "component---src-pages-success-subscribe-js" */),
  "component---src-templates-brand-page-js": () => import("./../../../src/templates/brand-page.js" /* webpackChunkName: "component---src-templates-brand-page-js" */),
  "component---src-templates-brands-page-js": () => import("./../../../src/templates/brands-page.js" /* webpackChunkName: "component---src-templates-brands-page-js" */),
  "component---src-templates-cart-page-js": () => import("./../../../src/templates/cart-page.js" /* webpackChunkName: "component---src-templates-cart-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-web-page-js": () => import("./../../../src/templates/web-page.js" /* webpackChunkName: "component---src-templates-web-page-js" */)
}

