import Api from "../services/Api";

// login api call
const login = async (values) => {
  const url = `${process.env.NETLIFY_API_BASE_ADURL}/auth/login`;
  const loginData = {
    username: values.email,
    password: values.password,
  };
  const response = await Api.post(url, loginData);
  return response.data;
};

// logout api call
const logout = async () => {
  const url = `${process.env.NETLIFY_API_BASE_ADURL}/auth/logout`;
  const response = await Api.post(url);
  return response.data;
};

const authService = { login, logout };

export default authService;
